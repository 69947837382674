import Api from '@/resources/Api'

// Data related to "Bonjour" page and used at ConversationProgress
const Statistics = {
    namespaced: true,
    strict: true,
    state: () => ({
        statistics: {},
        firstConversationId: null,
        defaultInbox: null,
        progress: null
    }),
    getters: {
        get: (state) => state.statistics,
        firstConversationId: (state) => state.firstConversationId,
        progress: (state) => state.progress
    },
    mutations: {
        SET_STATISTICS (state, payload) {
            state.statistics = {
                ...state.statistics,
                ...payload
            }

            const favicon = document.querySelector('link[rel="icon"]')
            if (favicon) {
                if (state.statistics[state.defaultInbox] === 0) {
                    if (favicon.href.indexOf('_unread.png') > -1) {
                        favicon.href = favicon.href.replace('_unread.png', '.png')
                    }
                } else {
                    if (favicon.href.indexOf('_unread.png') === -1) {
                        favicon.href = favicon.href.replace('.png', '_unread.png')
                    }
                }
            }
        },
        SET_FIRST_CONVERSATION (state, conversationId) {
            state.firstConversationId = conversationId
        },
        RESET_PROGRESS (state) {
            state.progress = {
                total: state.statistics[state.defaultInbox],
                processed: []
            }
        },
        DISABLE_PROGRESS (state) {
            state.progress = null
        },
        ADD_IN_PROGRESS (state, id) {
            if (!state.progress) return
            if (state.progress.processed.indexOf(id) === -1) {
                state.progress.processed.push(id)
            }
        },
        REMOVE_IN_PROGRESS (state, id) {
            if (!state.progress) return
            const index = state.progress.processed.indexOf(id)
            if (index > -1) {
                state.progress.processed.splice(index, 1)
            }
        },
        RESET_STATE (state) {
            state.statistics = {}
            state.progress = null
        },
        SET_DEFAULT_INBOX (state, inbox) {
            state.defaultInbox = inbox.key
        }
    },
    actions: {
        async load ({ state, commit, dispatch }, options) {
            /**
             * Options are:
             *     complete: boolean - Pass the "complete=true" request which will also count the total reading time of the default inbox
             *     reset: boolean - Reset the progress state
             */
            let url = '/conversations/status'
            if (options && options.complete) url += '?complete=true'

            try {
                const data = await Api.get(url)
                const params = {
                    ...data.statistics,
                    loaded: true // Used at Bonjour to show the statistics
                }
                if ('reading_time' in data) {
                    params.reading_time = data.reading_time || 0
                }
                commit('SET_STATISTICS', params)
                commit('SET_FIRST_CONVERSATION', data.first_conversation)
            } catch (error) {}
        },
        setDefaultInbox ({ commit }, inbox) {
            commit('SET_DEFAULT_INBOX', inbox)
        },
        resetProgress ({ commit }) {
            commit('RESET_PROGRESS')
        },
        disableProgress ({ commit }) {
            commit('DISABLE_PROGRESS')
        },
        increaseProgress ({ commit }, id) {
            commit('ADD_IN_PROGRESS', id)
        },
        decreaseProgress ({ commit }, id) {
            commit('REMOVE_IN_PROGRESS', id)
        }
    }
}

export default Statistics
