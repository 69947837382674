const install = function (app, options) {
    app.mixin({
        data () {
            return {
                perfCounter: null
            }
        },
        created () {
            this.perfCounter = performance.now()
        },
        mounted () {
            /*
            const time = performance.now() - this.perfCounter
            if (time > 100) {
                console.error(`[Perf] ${this.$options.name} mounted in ${time}ms`)
            } else if (time > 50) {
                console.warn(`[Perf] ${this.$options.name} mounted in ${time}ms`)
            }
            */
        }
    })
}

export default install
