import Vuex from 'vuex'

const store = new Vuex.Store({
    state: {
        $commands: null, // List of commands with key
        $options: null, // List of available options in the command center
        scope: null, // Current active scope (this),
        processing: null // In order to ensure a previous asynchronous options loading did not finished after a new one.
    },
    getters: {
        commands: (state) => state.$commands,
        options: (state) => {
            return state.$options
        },
        scope: (state) => state.scope
    },
    mutations: {
        addCommand (state, payload) {
            if (!state.$commands) {
                state.$commands = {}
            }
            state.$commands[payload.key] = payload.params
        },
        removeCommands (state, keys) {
            keys.forEach(k => delete state.$commands[k])
        },
        addOption (state, option) {
            if (!state.$options) {
                state.$options = []
            }
            state.$options.push(option)
        },
        resetOptions (state, { hash }) {
            /**
             * This is to ensure that a delayed updated won't affect an old request
             * That way, if the setOptions is later called for a hash that has changed, it will be ignored
             * It is for instance used when loading tickets in the Linear integration, which can take time
             */
            state.$options = null
            state.processing = hash
        },
        setOptions (state, { options, hash }) {
            if (state.processing === hash) {
                state.$options = options
            }
        },
        setScope (state, scope) {
            state.scope = scope
        }
    }
})

export default store
