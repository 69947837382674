import Storage from '@/resources/Storage'
import Api from '@/resources/Api'

class Environment {
    static PROD = 'prod'
    static DEMO = 'demo'
    static DEV = 'dev'
    static LOCAL = 'local'

    static env = null

    static getEnv () {
        if (this.env === null) {
            if (window.location.host === 'app.getfernand.com') {
                this.setEnv(this.PROD)
            } else if (window.location.host === 'demo.getfernand.com') {
                this.setEnv(this.DEMO)
            } else if (window.location.hostname === '127.0.0.1') {
                this.setEnv(this.LOCAL)
            } else {
                this.setEnv(this.DEV)
            }
        }
        return this.env
    }

    static isDev () {
        return this.getEnv() === this.DEV || this.getEnv() === this.LOCAL
    }

    static setEnv (env) {
        if (env !== 'prod') {
            console.log('Working on ' + env.toUpperCase() + ' environment.') // eslint-disable-line no-console
        }
        this.env = env
        switch (env) {
            case this.PROD:
                window.stripe_api_key = 'pk_live_51I6I9AAvbGuHuHKo0pKJigiN8sG7yxVqhLtbgv4iqSTYRFVS0KpC4LcvyoGHPa5tJCucNw9ay8rzNJktxhIIrAMD00vQxPOjjA'
                break
            default:
                window.stripe_api_key = 'pk_test_51I6I9AAvbGuHuHKoOaJ9Dc3ElZlnqhn8Ja1nfiBU0v0lFWBjEuzTY0CofC62OubhpNAzYXkErseOfpOdnxfKYi5p0057g0oWEb'
                break
        }
    }

    static async testEndpoint (baseUrl) {
        try {
            const response = await Api.get(baseUrl + '/ping')
            return response.toLowerCase().indexOf('fernand') > -1
        } catch (e) {}
        return false
    }

    static async getAPIUrl () {
        const customUrl = Storage.get('api_url')
        if (customUrl) {
            /*
            // Checking that the endpoint is a valid Reachful API server
            const baseUrl = customUrl.substr(0, customUrl.indexOf('/', 10))
            if (await this.testEndpoint(baseUrl)) {
                console.info('Using custom URL: ', customUrl) // eslint-disable-line no-console
                this.setEnv(this.DEV)
                return customUrl
            }
            */
            console.info('Using custom URL: ', customUrl) // eslint-disable-line no-console
            this.setEnv(this.DEV)
            return customUrl
        }

        if (this.getEnv() === this.PROD) {
            return 'https://api.getfernand.com'
        } else if (this.getEnv() === this.DEMO) {
            return 'https://api.demo.getfernand.com'
        } else {
            // First, starting with local URL just in case
            if (await this.testEndpoint('http://127.0.0.1:5000')) {
                console.info('Using LOCAL http://127.0.0.1:5000/') // eslint-disable-line no-console
                this.setEnv(this.LOCAL)
                return 'http://127.0.0.1:5000'
            } else {
                console.info('Using DEV https://api.getfernand.dev/') // eslint-disable-line no-console
                this.setEnv(this.DEV)
                return 'https://api.getfernand.dev'
            }
        }
    }
}

export default Environment
