const slugify = function (text, space) {
    const a = 'áäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa'
    const b = 'aaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa'
    const p = new RegExp(a.split('').join('|'), 'g')

    text = text.toString().toLowerCase()
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
        .replace(/[^\w- ]+/g, '') // Remove all non-word chars
        .trim()

    if (space && space !== ' ') {
        text = text.replaceAll(space, ' ').trim().replaceAll(' ', space)
        if (text.substring(0, 1) === space) text = text.substring(1)
        if (text.substring(text.length - 1) === space) text = text.slice(0, -1)
    }

    return text
}

export default slugify
