/**
 * Uses the appropriate storage system available
 * Depending on both the disponibility and the user's preferences
 */

export class CookieStorage {
    static expiration = 30 // 30 days expiry

    static setExpiration (days) {
        this.expiration = days
    }

    /**
     * Creates new cookie or removes cookie with negative expiration
     * @param  key       The key or identifier for the store
     * @param  value     Contents of the store
     */
    static setItem (key, value) {
        const date = new Date()
        date.setTime(date.getTime() + (this.expiration * 24 * 60 * 60 * 1000))
        const expires = '; expires=' + date.toGMTString()
        document.cookie = key + '=' + value + expires + '; path=/'
    }

    /**
     * Returns contents of cookie
     * @param  key       The key or identifier for the store
     */
    static getItem (key) {
        const nameEQ = key + '='
        const ca = document.cookie.split(';')
        for (let i = 0, max = ca.length; i < max; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
        }
        return null
    }

    static removeItem (key) {
        const oldExpiration = this.expiration
        this.expiration = -1
        this.setItem(key, '')
        this.expiration = oldExpiration
    }

    static clear (key) {
        document.cookie.split(';').forEach(c => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
        })
    }
}

class Storage {
    static storageSystem = null
    static allowedSystems = [window.localStorage, window.sessionStorage, CookieStorage]

    static _isStorageAvailable (storage) {
        let isAvailable = false
        try {
            const x = '__storage_test__'
            storage.setItem(x, x)
            if (storage.getItem(x) === x) {
                isAvailable = true
            }
            storage.removeItem(x)
        } catch (e) {}
        return isAvailable
    }

    static _detectStorageSystem () {
        let firstSelected = null
        for (let i = 0; i < this.allowedSystems.length; i++) {
            if (!this._isStorageAvailable(this.allowedSystems[i])) {
                continue
            }

            if (!firstSelected) {
                firstSelected = this.allowedSystems[i]
            }

            if (this.allowedSystems[i].getItem('_enforce') === 'true') {
                firstSelected = this.allowedSystems[i]
                break
            }
        }

        return firstSelected
    }

    static _getStorageSystem () {
        if (!this.storageSystem) {
            this.storageSystem = this._detectStorageSystem()
        }

        return this.storageSystem
    }

    static setStorageSystem (system) {
        if (this.allowedSystems.indexOf(system) === -1 || !this._isStorageAvailable(system)) {
            console.error('Invalid Underlying system provided!')
            return false
        }

        system.setItem('_enforce', 'true')
        this.storageSystem = system
    }

    static get (key, fallback = null) {
        let result = this._getStorageSystem().getItem(key)
        if (typeof (result) === 'string') {
            try {
                result = JSON.parse(result)
            } catch (e) {}
        }

        return result || fallback
    }

    static set (key, value) {
        if (typeof (value) === 'object') {
            value = JSON.stringify(value)
        }

        this._getStorageSystem().setItem(key, value)
        return value
    }

    static remove (key) {
        this._getStorageSystem().removeItem(key)
        return null
    }

    static clear () {
        this._getStorageSystem().clear()
        return null
    }
}

export default Storage
