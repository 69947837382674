const timers = {}

const debounce = (name, callback, wait) => {
    if (!(name in timers)) {
        timers[name] = null
    }

    if (timers[name]) {
        window.clearTimeout(timers[name])
    }

    timers[name] = window.setTimeout(() => {
        callback()
    }, wait)
}

const isMac = (/mac|ipod|iphone|ipad|macos/i.test(navigator.userAgentData?.platform || navigator.platform))

export { debounce, isMac }
