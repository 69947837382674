<template>
    <div class="modal" :class="{'is-visible': isVisible}">
        <div class="overlay transit" @click="hide()"></div>
        <div class="modal__content" ref="content">
            <div class="close" v-if="close" :class="{'disabled': !allowEsc}">
                <a href="javascript:;" title="Close this modal" @click.prevent="hide">&times;</a>
            </div>
            <slot v-if="!passive || isVisible" />
        </div>
    </div>
</template>

<script>
import Mousetrap from 'mousetrap'

export default {
    emits: ['hide', 'hidden', 'show', 'shown', 'onKeyEvent'],
    props: {
        close: {
            type: Boolean,
            default: true
        },
        allowEsc: {
            type: Boolean,
            required: false,
            default: true
        },
        trackKeyboard: {
            type: Boolean,
            required: false,
            default: true
        },
        passive: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        isVisible (to) {
            if (to) {
                // show
                if (this.trackKeyboard) Mousetrap.pause()
                window.addEventListener('keydown', this.onKeyEvent)
            } else {
                window.removeEventListener('keydown', this.onKeyEvent)
                if (this.trackKeyboard) Mousetrap.unpause()
            }
        }
    },
    data () {
        return {
            isVisible: false,
            isShowing: false,
            showTimeout: null,
            isHidding: false,
            hideTimeout: null
        }
    },
    beforeUnmount () {
        if (this.isVisible) {
            this.hide()
        }
    },
    methods: {
        show () {
            this.$emit('show')
            this.isVisible = true
            this.isShowing = true
            this.showTimeout = setTimeout(this.isShown, 550)
            this.$refs.content.addEventListener('transitionend', this.isShown, { once: true }, false)
        },
        hide () {
            if (!this.isVisible) {
                return false
            }

            this.$emit('hide')
            if (document.activeElement) {
                document.activeElement.blur()
            }
            this.hideTimeout = setTimeout(this.isHidden, 550)
            this.$refs.content.addEventListener('transitionend', this.isHidden, { once: true }, false)
            this.isVisible = false
            this.isHidding = true
        },
        onKeyEvent ($event) {
            if (!this.isVisible) return

            if ($event.key === 'Escape' && this.allowEsc) {
                $event.preventDefault()
                this.hide()
            }

            if (this.trackKeyboard) this.$emit('onKeyEvent', $event)
        },
        isShown () {
            if (this.showTimeout) {
                // The timeout is still running, it was called by the transitionend event
                clearTimeout(this.showTimeout)
            } else {
                // Called via the timeout, we remove the event
                this.$refs.content.removeEventListener('transitionend', this.isShown, { once: true }, false)
            }

            this.showTimeout = null
            if (this.isShowing) {
                this.isShowing = false
                this.$emit('shown') // First of the two
            }
        },
        isHidden () {
            if (this.hideTimeout) {
                // The timeout is still running, it was called by the transitionend event
                clearTimeout(this.hideTimeout)
            } else {
                // Called via the timeout, we remove the event
                this.$refs.content.removeEventListener('transitionend', this.isHidden, { once: true }, false)
            }
            this.hideTimeout = null
            if (this.isHidding) {
                this.isHidding = false
                this.$emit('hidden')
            }
        }
    }
}
</script>

<style lang="scss">
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    opacity:0;
    pointer-events:none;
    -webkit-transition: all .5s cubic-bezier(.19,1,.22,1);
    -moz-transition: all .5s cubic-bezier(.19,1,.22,1);
    -o-transition: all .5s cubic-bezier(.19,1,.22,1);
    transition: all .5s cubic-bezier(.19,1,.22,1);
    &.modal--large .modal__content {
        max-width:820px;
    }
    &.modal--medium .modal__content {
        max-width:640px;
    }
    &.is-visible {opacity:1;pointer-events:auto;}
    .modal__content {
        position: fixed;
        width:100%;
        max-width: 550px;
        background-color: var(--white);
        overflow: auto;
        border-radius: 4px;
        box-shadow: 0px 1px 4px #dadfea, 0px 9px 50px rgb(54 61 84 / 15%);
        z-index: 999;
        transform: scale(0.985);
        will-change: transform;
        -webkit-transition: all .2s cubic-bezier(.19,1,.22,1);
        -moz-transition: all .2s cubic-bezier(.19,1,.22,1);
        -o-transition: all .2s cubic-bezier(.19,1,.22,1);
        transition: all .2s cubic-bezier(.19,1,.22,1);
        .button {margin-bottom:0;}
        h1 {margin-bottom:32px;}
        section {
            margin-bottom:32px;
            &:last-child {margin-bottom:0;}
        }
    }
    &.is-visible .modal__content {transform: scale(1);}
    footer {
        background: var(--grey-light);
        border-top:1px solid var(--border);
        position:sticky;
        z-index:999;
        bottom:0;
        .panel__wrapper {padding:16px 32px;}
        .button {margin-right:12px;}
    }
}

@media screen and (max-width: 640px) {
   body .modal .modal__content {
       width:90%;
   }
}

</style>
