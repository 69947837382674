import store from '@/plugins/VueCommands/store'
import Mousetrap from 'mousetrap'

/**
 * adds a pause and unpause method to Mousetrap
 * this allows you to enable or disable keyboard shortcuts
 * without having to reset Mousetrap and rebind everything
 */
((Mousetrap) => {
    Mousetrap.prototype.pause = function () {
        this.paused = true
    }
    Mousetrap.prototype.unpause = function () {
        this.paused = false
    }

    Mousetrap.prototype.forceTrigger = null

    Mousetrap.init()
})(Mousetrap)

const install = (Vue) => {
    Vue.mixin({
        mounted () {
            if (!this.$options.commands) return
            this.$watch('$options.commands', (to, from) => this._bindCommands(to), { deep: true, immediate: true })
        },
        beforeUnmount () {
            if (!this.$options.commands) return
            store.commit('removeCommands', Object.keys(this.$options.commands))
        },
        /*
        beforeRouteLeave (to, from, next) {
            if (this.$options.commands) {
                store.commit('setCommands', null)
            }

            return next()
        },
        */
        methods: {
            _bindCommands (commands) {
                Object.entries(this.$options.commands).forEach(([key, command]) => {
                    const params = {
                        subtitle: command.subtitle || null,
                        icon: command.icon || null,
                        allowEmpty: command.allowEmpty || false, // Accepts empty values as valid
                        aliases: command.aliases || null, // Alias keys that will trigger that command
                        visible: () => true, // Is not visible. Ie: Can be not visible, but active!
                        active: () => true, // Can be called
                        handler: command.handler, // The function to call when the selection has been done
                        meta: command.meta || null, // Value to be used when calling via $command()
                        scope: this
                    }
                    if ('description' in command) {
                        if (typeof (command.description) === 'function') {
                            params.description = command.description
                        } else {
                            params.description = () => command.description
                        }
                    }

                    if ('example' in command) { params.example = command.example }

                    if ('visible' in command) {
                        if (typeof (command.visible) === 'function') {
                            params.visible = command.visible
                        } else {
                            params.visible = () => command.visible
                        }
                    }

                    if ('active' in command) {
                        if (typeof (command.active) === 'function') {
                            params.active = command.active
                        } else {
                            params.active = () => command.active
                        }
                    }

                    if ('icon' in command) {
                        if (typeof (command.icon) === 'function') {
                            params.icon = command.icon
                        } else {
                            params.icon = () => command.icon
                        }
                    }

                    if ('children' in command) {
                        params.children = command.children
                        params.orderChildrens = 'orderChildrens' in command ? command.orderChildrens : true
                    }
                    if ('validateChildren' in command) { params.validateChildren = command.validateChildren }

                    store.commit('addCommand', { key, params })
                })
            },
            $command (meta) {
                const command = Object.keys(store.getters.commands).find(key => ('meta' in store.getters.commands[key] && store.getters.commands[key].meta === meta))
                if (command) {
                    Mousetrap.forceTrigger(command)
                }
            },
            showControlPanel () {
                Mousetrap.trigger('mod+k')
            }
        }
    })
}

export default install
