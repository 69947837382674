<template>
    <span>
        <template v-for="key in keys" :key="key">
            <span v-if="key === 'then'" class="cmd-shortcut">then</span>
            <kbd v-else>{{ key }}</kbd>
        </template>
    </span>
</template>

<script>
import { isMac } from '@/resources/utils'

export default {
    props: {
        group: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    computed: {
        keys () {
            const value = this.$slots.default()[0]
            const results = value.children.replaceAll(' ', '+then+').split('+')
            for (let i = 0; i < results.length; i++) {
                if (results[i] === 'mod') {
                    results[i] = isMac ? '⌘' : 'ctrl'
                } else if (results[i] === 'shift') {
                    results[i] = '⇧'
                } else if (results[i] === 'alt') {
                    results[i] = isMac ? '⌥' : 'alt'
                }
            }

            if (this.group) {
                return [results.join('+')]
            }
            return results
        }
    }
}
</script>
