import defaults, { baseState, buildEndpointUrl } from './utils'
import Api from '@/resources/Api'

const snippets = {
    namespaced: true,
    state: baseState,
    getters: {
        ...defaults.getters,
        endpointUrl: (state) => (params) => buildEndpointUrl('snippets/', 'id', params)
    },
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        }
    },
    actions: {
        ...defaults.actions,
        async updateCategories ({ state, getters }, { id, categories }) {
            const endpointUrl = getters.endpointUrl({ instance: state.items[id], path: '/categories' })
            await Api.post(endpointUrl, { categories })
        }
    }
}

export default snippets
