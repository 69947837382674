import defaults, { baseState } from './utils'
import Api from '@/resources/Api'

const agentInvites = {
    namespaced: true,
    state: baseState,
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        }
    },
    getters: {
        ...defaults.getters,
        endpointUrl: (state) => ({ instance } = {}) => {
            if (!instance) return 'agents/invite'
            return `agents/invite/${instance.email}`
        }
    },
    actions: {
        ...defaults.actions,
        async all ({ getters, commit, dispatch }) {
            commit('ON_LOADING_START')
            // Don't put this as async, or it will be stuck waiting for the rest
            const result = await Api.get('agents/pending')
            result.data.forEach(result => {
                dispatch('_add', {
                    target_id: result.id,
                    document: result
                })
            })

            commit('ON_LOADING_STOP')
        }
    }
}

export default agentInvites
