import defaults, { baseState, buildEndpointUrl } from './utils'
import Api from '@/resources/Api'
import { reactive } from 'vue'

const contacts = {
    namespaced: true,
    state: baseState,
    getters: {
        ...defaults.getters,
        endpointUrl: (state) => (params) => buildEndpointUrl('contacts/', 'id', params)
    },
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        },
        ADD_ENTRY: (state, { targetId, document, getters }) => {
            document._last_conversations = reactive(document.last_conversations || [])

            Object.defineProperties(document, {
                last_conversations: {
                    get () {
                        if (!this._last_conversations) return []
                        return this._last_conversations.map(id => getters['conversations/get'](id))
                    }
                }
            })

            state.items[targetId] = document
        },
        VALIDATE_PAYMENT_LINKING: (state, { id, source }) => {
            if (!state.items[id].financials) return
            if (state.items[id].financials.sources[source]) {
                state.items[id].financials.sources[source].is_suggested = false
            }
        },
        REMOVE_PAYMENT_LINKING: (state, { id, source }) => {
            delete state.items[id].financials.sources[source]
            if (Object.keys(state.items[id].financials.sources).length === 0) {
                delete state.items[id].financials
                delete state.items[id].subscription_status
                delete state.items[id].subscription_start
            } else {
                if (state.items[id].financials?.subscriptions?.latest?.source === source) {
                    // Maybe some other subscriptions are related to other
                    delete state.items[id].financials.subscriptions
                }

                if (state.items[id].financials?.payments?.data?.length === 0) {
                    state.items[id].financials.payments.data = state.items[id].financials.payments.data.filter(x => x.source !== source)
                    state.items[id].financials.payments.total = state.items[id].financials.payments.total.filter(x => x.source !== source)
                }
            }
        }
    },
    actions: {
        ...defaults.actions,
        async conversations ({ state, getters, dispatch }, contactId) {
            const contact = await getters.getAsync(contactId)
            if (contact) {
                const result = await dispatch('conversations/load', { params: { search: 'from:' + contact.email, limit: 5 } }, { root: true })
                state.items[contactId]._last_conversations = result
            }
        },
        async refund ({ state, getters }, { id, chargeId, amount, reason }) {
            const instance = state.items[id]

            await Api.post(getters.endpointUrl({ instance, path: `/refund/${chargeId}` }), { amount, reason })
            const index = instance.financials.payments.data.findIndex(x => x.id === chargeId)

            instance.financials.payments.data[index].refunds.push({
                id: null,
                reason,
                amount: amount * 100,
                created: new Date().getTime() // Accepted here because linked to browser's interface
            })

            instance.financials.payments.data[index].prorated_payment -= (amount * 100)
        },
        async downgrade ({ state, getters }, { id, subscriptionId, when }) {
            const instance = state.items[id]
            const result = await Api.delete(getters.endpointUrl({ instance, path: `/subscriptions/${subscriptionId}` }), { params: { when } })
            // Too specific for a commit('UPDATE_ENTRY')
            instance.financials.subscriptions.latest = result
        },
        live ({ commit }, { contact_id }) { /* eslint-disable-line camelcase */
            commit('UPDATE_ENTRY', {
                targetId: contact_id, /* eslint-disable-line camelcase */
                document: {
                    isConnected: true
                }
            })
        },
        left ({ commit }, { contact_id }) { /* eslint-disable-line camelcase */
            commit('UPDATE_ENTRY', {
                targetId: contact_id, /* eslint-disable-line camelcase */
                document: {
                    isConnected: false
                }
            })
        },
        async linkPaymentEmail ({ state, getters, commit }, { id, email, source }) {
            const instance = state.items[id]
            commit('VALIDATE_PAYMENT_LINKING', { id, source })
            return await Api.post(getters.endpointUrl({ instance, path: '/payment-email' }), { email, source })
        },
        async unlinkPaymentEmail ({ state, getters, commit }, { id, email, source }) {
            const instance = state.items[id]
            commit('REMOVE_PAYMENT_LINKING', { id, source })
            return await Api.delete(getters.endpointUrl({ instance, path: '/payment-email' }), { data: { email, source } })
        }
    }
}

export default contacts
