import Api from '@/resources/Api'
import { EventNotifications } from '@/resources/WebSocket'

const install = function (app, options) {
    app.mixin({
        beforeMount () {
            if (!this.$options.$events) return

            this._socketHandlers = {}

            // Add listeners:
            Object.entries(this.$options.$events).forEach(([key, handler]) => {
                this._socketHandlers[key] = (params) => {
                    handler.apply(this, [params])
                }

                EventNotifications.on(key, this._socketHandlers[key])
            })
        },
        beforeUnmount () {
            if (!this.$options.$events) return

            Object.entries(this.$options.$events).forEach(([key, handler]) => {
                EventNotifications.off(key, this._socketHandlers[key])
            })

            this._socketHandlers = {}
        }
    })

    const events = {
        async dispatch (action, value = null) {
            await Api.post('/stream/event', { action, value })
        }
    }

    app.events = events
    app.config.globalProperties.$events = events
}

export default install
