import defaults, { baseState, buildEndpointUrl } from './utils'
import Api from '@/resources/Api'

/**
 * Careful here, the categories are flattened, and not built in a tree way
 */

const snippetCategories = {
    namespaced: true,
    state: baseState,
    getters: {
        ...defaults.getters,
        endpointUrl: (state) => (params) => buildEndpointUrl('snippets/categories/', 'id', params)
    },
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        },
        BLUR: (state, id) => {
            delete state.items[id].$focus
        },
        REMOVE_PENDING: (state) => {
            delete state.items.pending
        },
        UPDATE_PARENT_ID: (state, { id, parentId }) => {
            state.items[id].parent_id = parentId
        }
    },
    actions: {
        ...defaults.actions,
        blur ({ state, commit }) {
            Object.values(state.items).forEach(x => {
                if (x.$focus) commit('BLUR', x.id)
            })
        },
        addChild ({ state }, { parentId = null }) {
            /**
             * This function will add a fake child until a name was properly set
             */

            // First, we increase the sort number of +1 to all the existing childs
            Object.values(state.items).forEach(x => {
                if (x.parent_id === parentId) {
                    if (!x.sort) x.sort = 1
                    x.sort++
                }
            })

            // Then we add the new child
            state.items.pending = {
                id: 'pending',
                name: null,
                parent_id: parentId,
                sort: 1,
                $focus: true // Triggers the focus for new childs
            }
        },
        async setName ({ state, dispatch }, { id, name }) {
            if (!name) {
                // We do nothing in that case
                if (id === 'pending') dispatch('removePending')
                return
            }

            if (id === 'pending') {
                // We create a new entry
                return await dispatch('create', { name, parent_id: state.items.pending.parent_id })
            } else {
                return await dispatch('update', { id, document: { name } })
            }
        },
        removePending ({ state, commit }) {
            if ('pending' in state.items) {
                commit('REMOVE_PENDING')
            }
        },
        updateTree ({ state, dispatch, commit }, { action, parentId, id, newIndex = null, oldIndex = null }) {
            // We don't handle remove because remove is always followed by an add (it's an update)
            if (action === 'remove') return

            // The event we must send is
            // the category id, parent id, position
            dispatch('updatePosition', { id, parentId, position: newIndex })

            if (action === 'add') {
                commit('UPDATE_PARENT_ID', { id, parentId })
                oldIndex = 1000 // since it's a new one, we consider all the next items to be modified
            }

            // What remains now, is either action "move" or "add" that needs to be placed properly

            // First, we get a list of currently ordered item
            const items = Object.values(state.items).filter(category => category.parent_id === parentId).sort((a, b) => a.sort - b.sort)

            // I'll try:
            let index = 0
            items.forEach(category => {
                if (index === newIndex) {
                    state.items[id].sort = index + 1
                    index += 1
                }

                if (category.id === id) {
                    return
                }
                category.sort = index + 1
                index += 1
            })
        },
        async updatePosition ({ state, getters }, { id, parentId, position }) {
            await Api.patch(getters.endpointUrl({ instance: state.items[id], path: '/position' }), { id, position, parent_id: parentId })
        }
    }
}

export default snippetCategories
