import organizations from './Organizations'
import channels from './Channels'
import agents from './Agents'
import agentInvites from './AgentInvites'

import conversations from './Conversations'
import messages from './Messages'
import tags from './Tags'

import contacts from './Contacts'

import snippetCategories from './SnippetCategories'
import snippets from './Snippets'

export default {
    organizations,
    channels,
    agents,
    agentInvites,
    conversations,
    messages,
    tags,
    contacts,
    snippetCategories,
    snippets
}
