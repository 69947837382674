import axios from 'axios'
import store from '@/stores/store'
import { v4 as uuidv4 } from 'uuid'

class WSSocket {
    constructor () {
        this.url = null
        this.token = null
        this.ws = null
        this.lastEvent = null
        this.tasks = []
        this.wsState = WebSocket.CLOSED
        this.callback = null
    }

    setUrl (url) {
        this.url = url.replace('http', 'ws')
    }

    setToken (token) {
        this.token = token
    }

    clear () {
        this.token = null
        delete axios.defaults.headers.common['X-Client-Session']
        this.close() // Handle the connection there
    }

    connect (callback = null) {
        if (callback) {
            if (this.callback) console.warn('A callback is already set')
            this.callback = callback
        }

        if (this.wsState !== WebSocket.CLOSED) return

        if (!this.url || !this.token) {
            // throw new Error('Unable to connect to Websocket without a valid URL and Token.')
            return
        }

        this.wsState = WebSocket.CONNECTING
        let init = ''
        if (!('X-Client-Session' in axios.defaults.headers.common)) {
            axios.defaults.headers.common['X-Client-Session'] = uuidv4()
            init = '&init=true'
        }
        const session = axios.defaults.headers.common['X-Client-Session']

        this.ws = new WebSocket(`${this.url}/stream?token=${this.token}&session=${session}${init}`)

        this.ws.addEventListener('close', ($event) => { this._handleDisconnect($event) }, { once: true })

        // Adding the onMessage event here
        this.ws.addEventListener('message', ($event) => this.onMessage($event))

        this.ws.addEventListener('open', (event) => {
            this.wsState = WebSocket.OPEN
            const currentTime = new Date().getTime()
            if (this.lastEvent && currentTime - this.lastEvent > 5000) {
                console.warn('Reconnected after ' + (currentTime - this.lastEvent) + 'ms')
            }
            console.info('%c i %c Connected to WebSocket at ' + new Date().toISOString(), 'color: white; background: blue;', '')

            // Sends all the pending tasks
            while (this.tasks.length > 0) {
                const task = this.tasks.shift()
                this.ws.send(JSON.stringify(task))
            }

            this.lastEvent = new Date().getTime()
        }, { once: true })
    }

    close () {
        if (this.wsState !== WebSocket.OPEN) return
        this.wsState = WebSocket.CLOSED

        this.lastEvent = new Date().getTime()

        // We remove the close first to avoid auto-reconnect
        this.ws.close()
        this.ws = null
    }

    async _handleDisconnect ($event) {
        this.wsState = WebSocket.CLOSED
        this.lastEvent = new Date().getTime()
        try {
            const reason = JSON.parse($event.reason)
            if (reason.code === 401) {
                this.setToken(null)
                store.dispatch('disconnected')
                return
            }
        } catch (e) {
            console.error('A fatal error occured')
            console.error(e)
            return
        }

        /**
         * @see https://developer.mozilla.org/fr/docs/Web/API/CloseEvent
         * 1000: Normal closure; the connection successfully completed whatever purpose for which it was created.
         */
        if ($event.code === 1000) return

        // In case we are offline, we wait ...
        while (window.navigator.onLine === false) {
            await new Promise((resolve, reject) => setTimeout(resolve, 1000))
        }
        this.connect()
    }

    async onMessage (event) {
        this.lastEvent = new Date().getTime()
        let struct = event.data

        if (struct.substr(0, 1) === '{') {
            struct = JSON.parse(struct)
        }

        if (struct.action === 'READY') {
            if (this.callback) {
                this.callback()
                this.callback = null
            }
        } else if (struct.action === 'DONE') {
            delete struct.action
            store.dispatch('onRequestComplete', struct)
        } else if (struct.action === 'ERROR') {
            delete struct.action
            store.dispatch('onRequestFailure', { id: struct.target_id, document: struct.document })
        } else if ('col' in struct && struct.col) {
            let action = struct.action.toLowerCase()
            if (action === 'read') action = 'add' // Because a read on the back is an insert/update on the front
            if (struct.col + '/_' + action in store._actions) {
                store.dispatch(struct.col + '/_' + action, struct)
            }

            if (action === 'fetch') action = 'add' // Here it's a special case because fetch behave like add
            EventNotifications.trigger(struct.col + ':' + action, { id: struct.target_id, document: struct.document })
        } else {
            if (!('documents' in struct)) {
                struct.documents = [struct.document]
            }

            struct.documents.forEach(document => {
                EventNotifications.trigger(struct.action, document)
            })
        }
    }

    async emit ({ action }) {
        if (this.isConnected()) {
            try {
                this.ws.send(JSON.stringify({ action }))
            } catch (e) {}
        } else {
            this.tasks.push({ action })
        }
    }
}

const WS = new WSSocket()

export default WS

export const EventNotifications = (function () {
    const _listeners = {}

    return {
        on (event, callback) {
            if (!(event in _listeners)) {
                _listeners[event] = []
            }

            if (_listeners[event].indexOf(callback) > -1) return // Already registered
            _listeners[event].push(callback)
        },
        off (event, callback = null) {
            if (!(event in _listeners)) return
            if (callback !== null) {
                const index = _listeners[event].indexOf(callback)
                if (index === -1) return // Not present
                _listeners[event].splice(index, 1)
            } else {
                delete _listeners[event]
            }
        },
        async trigger (action, document) {
            if (!(action in _listeners)) return
            _listeners[action].forEach(x => {
                Promise.resolve(x(document))
            })
        }
    }
})()
